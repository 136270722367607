<template>
  <div class="view-wrapper">
    <div class="view">
      <v-toolbar class="my-bar">
        <v-text-field
          v-model="searchText"
          hide-details
          append-icon="search"
          single-line
          dense
          filled
          rounded
          clearable
          @keydown.enter="search"
          @click:append="search"
        ></v-text-field>
        <v-btn v-if="rightFilter('w')" icon @click="add">
          <v-icon>add</v-icon>
        </v-btn>
        <v-dialog v-if="rightFilter('d')" v-model="dialogDelete" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon :disabled="!item || !item.id" v-bind="attrs" v-on="on">
              <v-icon>remove</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline"
            >Вы точно хотите удалить диагноз?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogDelete = false">Отмена</v-btn>
              <v-btn color="primary" text @click="rem">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer />
      </v-toolbar>
      <div class="my-content">
        <v-list>
          <v-list-item-group v-model="selected" color="primary">
            <template v-for="item in filteredItems">
              <v-list-item :key="filteredItems.indexOf(item)">
                <v-list-item-content>
                  <v-list-item-title v-html="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="filteredItems.indexOf(item)"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
    <div v-if="item" class="view">
      <v-toolbar class="my-bar">
<!--        <v-btn v-if="rightFilter('w')" icon @click="save">
          <v-icon>save</v-icon>
        </v-btn>-->
        <SaveButton
        :handler="save"
        ></SaveButton>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="my-content">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="item.name"
                  :label="'Наименование'"
                  :rules="requiredRules"
                  clearable
                  autofocus
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import fetch from "../utils/fetch";
import js2vm from "@/utils/js2vm";
import vm2js from "../utils/vm2js";
import rightFilter from "../filters/right.filter";
import SaveButton from "@/components/ButtonFacade";

let Item = function(d) {
  let self = this;
  js2vm(self, d);
};

export default {
  components: {SaveButton},
  data: () => ({
    rightFilter,
    currentPage: 0,
    totalPages: 0,
    items: [],
    selected: undefined,
    searchText: undefined,
    item: null,
    loading: false,
    dialogDelete: false,
    requiredRules: [v => !!v || "Обязательное поле"],
    valid: false,
  }),
  methods: {
    search() {
      this.items = [];
      this.selected = undefined;
      this.currentPage = 0;
      const params = {
        page: this.currentPage,
        predicate: this.searchText
      };
      this.fetchItems(params);
    },
    async fetchItems(params) {
      this.loading = true;
      let res;
      if (!params.predicate)
        res = await fetch.get(
          "/api/medcases/diagnosis/get-list?page=" + params.page
        );
      else
        res = await fetch.get(
          "/api/medcases/diagnosis/search?predicate=" +
            params.predicate +
            "&page=" +
            params.page
        );
      if (res.first) {
        this.totalPages = res.totalPages;
        this.items = [];
      }
      res.content.forEach(r => {
        this.items.push(new Item(r));
      });
      this.loading = false;
    },
    add() {
      this.close();
      this.selected = new Item({});
    },
    async rem() {
      const res = await fetch.post(
        "/api/medcases/diagnosis/delete?id=" + this.item.id
      );
      if (res) {
        this.dialogDelete = false
        const index = this.items.indexOf(this.selected)
        this.close();
        this.items.splice(index, 1);
      }
    },
    async save() {
      if (!this.valid) return
      const res = await fetch.post(
        "/api/medcases/diagnosis/save",
        vm2js(this.item)
      );
      if (res) {
        if (this.item.id) {
          Object.assign(
            this.items.find(i => {
              return i.id === this.item.id;
            }),
            this.item
          );
        } else {
          this.item.id = res;
          this.items.push(new Item(vm2js(this.item)));
        }
        this.close();
      }
    },
    close() {
      this.selected = undefined;
    },
    handleScroll(e) {
      if (
        e.target.scrollTop >=
        (e.target.scrollHeight - e.target.offsetHeight) * 0.9
      ) {
        if (this.totalPages - 1 > this.currentPage) {
          this.currentPage++;
          const params = {
            page: this.currentPage
          };
          this.fetchItems(params);
        }
      }
    }
  },
  computed: {
    filteredItems() {
      return this.items
    }
  },
  watch: {
    selected: function(val) {
      if (val === undefined) return (this.item = undefined);
      this.item = new Item(vm2js(this.items[val]));
    }
  },
  mounted() {
    document
      .getElementsByClassName("my-content")[0]
      .addEventListener("scroll", this.handleScroll);
    this.fetchItems({ page: 0 });
  }
};
</script>
